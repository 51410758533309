import React, { useEffect, useState, } from "react";
import 'react-awesome-slider/dist/styles.css';
import { useLocation, useNavigate } from "react-router-dom";
import logo from '../img/logo.svg'

const SplashScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    

    useEffect(() => {
        window.scrollTo(0, 0)
       
            var timer = setTimeout(()=> {
              navigate('/home');
            }, 10500);
         
    }
      , []);

      
    
    
    return(
    <div style={{backgroundColor:'#e7be41',height:'100vh'}}>
        <div className="global d-flex justify-content-center responsivePadding" style={{backgroundColor:'#e7be41'}}> 
        <img 
              src={logo} 
              alt="logo"
              className="elementToFadeInAndOut responsiveWidth"
            />
        </div>
    </div>
    )
}

export default SplashScreen;