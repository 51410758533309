import React, { useEffect, useState, } from "react";
import companyLogo from '../assets/images/logo-3-bg.png';
import Header from "../common/Header";
import SideMenu from "../common/SideMenu";
import Footer from "../common/Footer";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { AdminProfile } from "../Context/AdminContext";
import { Modal } from "react-bootstrap";
import edit from "../assets/images/edit.png";
import trash from "../assets/images/trash.png"

const ContactPage = () => {

  const navigate = useNavigate();
  const { authData} = useAuth();
  const {contactList}=AdminProfile();
  const [categoriesModal, setcategoriesModal] = useState(false);
  const [menuName, setMenuName] = useState('');
  const [menuDesc, setMenuDesc] = useState('');
  const [imageHandler, setImageHandler] = useState(null)
  const [categoryId, setCategoryId] = useState(0);
  

  useEffect(() => {
  }
    , [authData]);

    useEffect(() => {
      console.log("contact list===>",contactList)
    }
      , [contactList]);

    const openModal=()=>{
      setcategoriesModal(true)
    }

    const closecategoriesModal=()=>{
      setcategoriesModal(false)
    }

    const uploadImage=(e)=>{
      setImageHandler(e.target.files[0])
    }
    const selectCategory=(e)=>{
      setCategoryId(e.target.value)
    }
    

    return(
      <div className="container-scroller">
      
      
      <Header/>
      
      <div className="container-fluid page-body-wrapper">
        
        <SideMenu/>
        
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="page-header d-flex justify-conetent-between">
              <h3 className="page-title">
                 Manage Contact
              </h3>

              
              
            </div>
            
            
            <div className="row">
            
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th> Name </th>
                            <th> Email </th>
                            <th> Phone </th>
                            <th> Message</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {contactList.map((data, index) => (
                          <tr key={index}>

                            <td>
                              {data.name}
                            </td>
                            <td>{data.email}</td>
                            <td>{data.contactNo}</td>
                            <td>
                              {data.comment}
                            </td>
                            <td></td>
                           
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                
              </div>
            
            </div>
            
          </div>
          <Footer/>
          
          
        </div>
        
      </div>

      {/* ------------Add Edit Category Modal------------ */}

      <Modal
          show={categoriesModal}
          onHide={closecategoriesModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Menu</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 mb-3">
                
                
                <label className="form-label">Menu Name <span style={{color:'red'}}>*</span></label>
                <input 
                    type="text" 
                    className="form-control mb-2" 
                    placeholder="Type Category Name"
                    value={menuName} 
                    onChange={(e) => setMenuName(e.target.value)} 
                />
                <label className="form-label">Description <span style={{color:'red'}}>*</span></label>
                <textarea 
                className="form-control mb-2"
                onChange={(e) => setMenuDesc(e.target.value)} 
                value={menuDesc}
                />
                

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            {/* {categoryId === 0 &&
              <div className="text-danger">All fields are mandatory</div>
            } */}
            
          </Modal.Footer>
        </Modal>
      
    </div>
    )
}

export default ContactPage;