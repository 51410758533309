import React, { useEffect, useState,} from "react";
import { useNavigate } from 'react-router-dom';
import companyLogo from '../../img/logo.svg';

const Header=(props)=> {
  const navigate = useNavigate();

  const doLogout=()=>{
    navigate('/admin')
  }
  
  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
          <div className="navbar-brand brand-logo" href="index.html">
            <img src={companyLogo} alt="logo" style={{
              height:50,
              width:50
            }}/>
            </div>
          
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          
          
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile dropdown">
              <div className="nav-link">
                
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">Welcome , Admin</p>
                </div>
              </div>
              
            </li>
            
            
            
            <li className="nav-item nav-logout d-none d-lg-block">
              
              <button className="btn btn-outline-info btn-sm" onClick={doLogout}>
                  Logout
              </button>
            </li>
            
          </ul>
          
        </div>
      </nav>
  );
}

export default Header;
