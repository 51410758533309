import logo from './logo.svg';
// import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
//import "./css/bootstrap.css";
import "./css/style.css";
import "./css/camera.css";
import AppRouter from './AppRouter';
import { UserProvider } from './Context/UserContext';

const App=()=> {
  return (
    <UserProvider>
        <AppRouter/>
    </UserProvider>
    
  );
}

export default App;
