import React, { useEffect, useState, } from "react";
import companyLogo from '../assets/images/logo-3-bg.png';
import Header from "../common/Header";
import SideMenu from "../common/SideMenu";
import Footer from "../common/Footer";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { AdminProfile } from "../Context/AdminContext";
import { Modal } from "react-bootstrap";
import edit from "../assets/images/edit.png";
import trash from "../assets/images/trash.png"
import {convertDateTime,convertTime} from "../Config/CommonFunction.js"
import BootstrapSwitchButton from "bootstrap-switch-button-react";

const EventPage = () => {

  const navigate = useNavigate();
  const { authData} = useAuth();
  const {eventList,addEvent,updateEvent,activeInactiveEvent}=AdminProfile();
  const [categoriesModal, setcategoriesModal] = useState(false);
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventTime, setEventTime] = useState('');
  const [eventDesc, setEventDesc] = useState('');
  const [editedEventId, setEditedEventId] = useState(0);
  
  const [imageHandler, setImageHandler] = useState(null)

  useEffect(() => {
    console.log("eventList===>",eventList)
  }
    , [authData]);

    useEffect(() => {
    }
      , [eventList]);

    const openCategoeyModal=()=>{
      setEditedEventId(0)
      setEventName('')
      setEventDate('')
      setEventDesc('')
      setcategoriesModal(true)
    }

    const closecategoriesModal=()=>{
      setcategoriesModal(false)
    }

    const uploadImage=(e)=>{
      // let arr=[]
      // for(let i=0;i<e.target.files.length;i++){
      //   arr.push(e.target.files[i])
      // }
      setImageHandler(e.target.files)
    }

    const saveCategory=async()=>{
      console.log("IMG FILES===>",imageHandler)
      if(eventName==='' || eventDate==='' ||  eventDesc==='' || eventTime==='')
      {
        alert('All * marks fields are mandatory')
      }
      else{
        let resp=''
        const formData = new FormData();
        formData.append('id', editedEventId)
        formData.append('name', eventName)
        formData.append('ImageCount', 1)
        formData.append('EventDate', eventDate+' '+eventTime)
        formData.append('Description', eventDesc)
        if(imageHandler!==null){
          for(let i=0;i<imageHandler.length;i++){
            formData.append('ImageFiles', imageHandler[i])
          }
        }
        
        if(editedEventId===0){
          resp=await addEvent(formData)
        }
        else{
          resp=await updateEvent(formData)
        }
        
        setcategoriesModal(false)
        if(resp.data.status==="success"){
          alert(resp.data.message)
        }
        else{
          alert("Failed to save")
        }
      }
    }

    const openEditSection=async(data)=>{
      setEditedEventId(data.id)
      setEventName(data.name)
      setEventDate(convertDateTime(data.eventDate))
      setEventDesc(data.description)
      setcategoriesModal(true)
      
    }

    const changeStatus=async(id)=>{
      const resp=await activeInactiveEvent(id)
    }
    return(
      <div className="container-scroller">
      
      
      <Header/>
      
      <div className="container-fluid page-body-wrapper">
        
        <SideMenu/>
        
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="page-header d-flex justify-conetent-between">
              <h3 className="page-title">
                 Manage Event
              </h3>

              <button className="btn btn-dark btn-fw" onClick={openCategoeyModal}>
                  Add
              </button>
              
            </div>
            
            
            <div className="row">
            
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th> Name </th>
                            <th> Description </th>
                            <th> Date </th>
                            <th>Time</th>
                            <th> Status </th>
                            <th> </th>
                            <th> </th>
                          </tr>
                        </thead>
                        <tbody>
                        {eventList.map((data, index) => (
                          <tr key={index}>
                            <td>
                              {data.name}
                            </td>
                            <td> {data.description.substring(0, 20)}... </td>
                            <td>
                              {convertDateTime(data.eventDate)}
                            </td>
                            <td>{convertTime(data.eventDate)}</td>
                            <td>
                              {data.isActive===true?(
                                <label className="badge badge-gradient-success">Active</label>
                              ):(
                                <label className="badge badge-gradient-danger">Inactive</label>
                              )}
                            </td>
                            <td> 
                            <button 
                            className="btn btn-inverse-primary btn-icon"
                            onClick={()=>openEditSection(data)}
                            >
                                  <img 
                                    src={edit}
                                    style={{
                                      height:'24px',
                                      width:'24px'
                                    }}
                                  />
                              </button>
                            </td>
                            <td>
                            <BootstrapSwitchButton 
                              checked={data.isActive} 
                              onstyle="success" 
                              offstyle="secondary" 
                              size="lg"
                              onChange={() => changeStatus(data.id)}
                              />
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                
              </div>
            
            </div>
            
          </div>
          <Footer/>
          
          
        </div>
        
      </div>

      {/* ------------Add Edit Category Modal------------ */}

      <Modal
          show={categoriesModal}
          onHide={closecategoriesModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 mb-3">
                <label className="form-label">Event Name <span style={{color:'red'}}>*</span></label>
                <input 
                    type="text" 
                    className="form-control mb-2" 
                    placeholder="Type Event Name"
                    value={eventName} 
                    onChange={(e) => setEventName(e.target.value)} 
                />
                <label className="form-label">Event Date & Time<span style={{color:'red'}}>*</span></label>
                <div className="row">
                <div className="col-6">
                    <input 
                        type="date" 
                        //readOnly={true}
                        className="form-control mb-2" 
                        //placeholder="Type Category Name"
                        value={eventDate} 
                        onChange={(e) => setEventDate(e.target.value)} 
                    />
                </div>
                  <div className="col-6">
                      <input 
                        type="time" 
                        //readOnly={true}
                        className="form-control mb-2" 
                        //placeholder="Type Category Name"
                        value={eventTime} 
                        onChange={(e) => setEventTime(e.target.value)} 
                    />
                  </div>
                </div>
                
                
                <label className="form-label">Event Description <span style={{color:'red'}}>*</span></label>
                <textarea
                  className="form-control mb-2" 
                  value={eventDesc} 
                  onChange={(e) => setEventDesc(e.target.value)} 
                />
                <label className="form-label">Upload Image <span style={{color:'red'}}>*</span></label>
                <input 
                  type="file" 
                  className="form-control mb-2" 
                  onChange={uploadImage}
                  multiple={true}
                  />

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            
            <button className="btn btn-outline-dark btn-fw" onClick={saveCategory} style={{ width: '20%' }}>
              Save
            </button>
          </Modal.Footer>
        </Modal>
      
    </div>
    )
}

export default EventPage;