import React, { useEffect, useState, } from "react";

import AwesomeSlider from 'react-awesome-slider';
//import withCaption from 'react-awesome-slider/dist/captioned';
import 'react-awesome-slider/dist/styles.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import config from "../Config/Config.json"
import event1 from '../img/2150951243.jpg'
import fb from '../img/fb.png'
import insta from '../img/insta.png'


import Header from "../common/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { UserProfile } from "../Context/UserContext";
import { convertDateTime, convertTime } from "../admin/Config/CommonFunction";
import { Modal } from "react-bootstrap";


//const CaptionedSlider = withCaption(AwesomeSlider);

const EventDetailsScreen = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const {allEvent,getEventDetailsById,allGallery}=UserProfile();
    const [eventDetails,setEventDetails]=useState('')
    const [eventImageArr,setEventImageArr]=useState([])
    const [eventDate,setEventDate]=useState('')
    const [eventTime,setEventTime]=useState('')
    const [galleryModal,setGalleryModal]=useState(false)
    const [imagePath,setImagePath]=useState('')


    useEffect(() => {
        window.scrollTo(0, 0)
        getEventdetails();
    }
      , [location.state.event_id]);


    const getEventdetails=async()=>{
        const resp=await getEventDetailsById(location.state.event_id)
        console.log("IMG ARRAY===>",resp)
        setEventDetails(resp.data)
        setEventDate(convertDateTime(resp.data.eventDate))
        setEventTime(convertTime(resp.data.eventDate))
        setEventImageArr(resp.data.eventImages)
       
        
    }
    const openModal=(path)=>{
        setImagePath(path)
        setGalleryModal(true)
    }
    const closeGalleryModal=()=>{
        setImagePath('')
        setGalleryModal(false)
    }
    return(
      <div>
     <Header/>

  <div className="global"> 
    
 
  <div className="container container_min_height">
        
        <div className="col-lg-12 events mb-5">
            
            <div className="row d-flex pt-3" style={{flexWrap:'wrap'}}>
                <div className="col-lg-6">
                    <img src={eventDetails.imageCount>0?config.FILE_URL+eventDetails.eventImages[0]['imageName']:event1} alt="" style={{width:'100%'}}/>
                </div>
                <div className="col-lg-6">
                    <p className="menuTitle mb-3">{eventDetails.name}</p>
                    <p className="menuTitle mb-3">{eventDate} {eventTime}</p>
                    <p className="menuDesc">{eventDetails.description}</p>
                </div>
            </div>
        </div>
    </div>

    <div className="forcombo gallery_back py-2 px-3">
    <div className="col-lg-12">
            <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                <h3 className="menu_heading_combo">Event Gallery</h3>
            </div>
            <div className="row d-flex mb-3 px-3" style={{flexWrap:'wrap',flexDirection:'row',cursor:'pointer'}}>
            {eventImageArr.map((data, index) => (
                    <div style={{width:'20%',padding:0}} key={index} onClick={()=>openModal(config.FILE_URL+data.imageName)}>
                        <img src={config.FILE_URL+data.imageName} alt="" style={{width:'100%',height:'150px'}}/>
                    </div>
            ))}
            </div>
    </div>
</div>

<Modal
          show={galleryModal}
          onHide={closeGalleryModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 mb-3">
                
                
                
              <img src={imagePath} alt="" style={{width:'100%'}}/>
                

              </div>
            </div>
          </Modal.Body>
          </Modal>
    
    
    

   
    
        
    <div className="footer_dark py-2 px-3">
        <div className=" d-flex align-items-center justify-content-between">
            <h6>The Lighter Side &copy; 2024</h6>
            <div className="d-flex align-items-center justify-content-between">
                <img src={fb} alt="fb" style={{height:30,width:30}}/>
                <img src={insta} alt="insta" style={{height:30,width:30}}/>
            </div>
        </div>
        
    </div>
    
</div>
  </div>
    )
}

export default EventDetailsScreen;