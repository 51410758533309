import React, { useEffect, useState,} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import fb_dark from '../img/fb_dark.png'
import insta_dark from '../img/insta_dark.png'
import phone_dark from '../img/phone_dark.png'
import location_dark from '../img/location_dark.png'
import logo from '../img/logo.svg'
import menu_mobile from '../img/menu.svg'
import { Link } from "react-router-dom";

const Header=(props)=> {
  const navigate = useNavigate();
  const location = useLocation();
  const [homeClass,setHomeClass]=useState('active')
  const [catClass,setCatClass]=useState('')
  const [eventClass,setEventClass]=useState('')
  const [galleryClass,setGalleryClass]=useState('')
  const [comboClass,setComboClass]=useState('')
  const [drawerStat,setDrawerStat]=useState(false)
  

  useEffect(() => {
        if(location.pathname==='/home'){
            setHomeClass('active')
            setCatClass('')
            setEventClass('')
            setGalleryClass('')
            setComboClass('')
        }
        else if(location.pathname==='/categories'){
            setHomeClass('')
            setCatClass('active')
            setEventClass('')
            setGalleryClass('')
            setComboClass('')
        }
        else if(location.pathname==='/event'){
            setHomeClass('')
            setCatClass('')
            setEventClass('active')
            setGalleryClass('')
            setComboClass('')
        }
        else if(location.pathname==='/gallery'){
            setHomeClass('')
            setCatClass('')
            setEventClass('')
            setGalleryClass('active')
            setComboClass('')
        }
        else if(location.pathname==='/menu'){
            setHomeClass('')
            setCatClass('active')
            setEventClass('')
            setGalleryClass('')
            setComboClass('')
        }
        else if(location.pathname==='/eventdetails'){
            setHomeClass('')
            setCatClass('')
            setEventClass('active')
            setGalleryClass('')
            setComboClass('')
        }
        else if(location.pathname==='/combo'){
            setHomeClass('')
            setCatClass('')
            setEventClass('')
            setGalleryClass('')
            setComboClass('active')
        }
        else{
            setHomeClass('')
            setCatClass('')
            setEventClass('')
            setGalleryClass('')
            setComboClass('')
        }
  }
    , []);


    const openDrawer=()=>{
        setDrawerStat(!drawerStat)
    }
    const gotoPage=(pageName)=>{
        setDrawerStat(false)
        navigate('/'+pageName)
    }
  return (
    <header>
    <div className="container desktop_menu"> 
    <div className="mb-3 d-flex justify-content-center">
          
          <img 
              src={logo} 
              alt="logo"
              style={{
                width: '13%',
                position: 'absolute',
                background: '#f7e8da',
                borderRadius: '50%'
              }}
            />
          
          
      </div>
      <div className="d-flex align-items-center justify-content-end">
        {/* <h1 className="navbar-brand navbar-brand_ navbar_logo_color">
          
              <img 
              src={logo} 
              alt="logo"
              style={{width:'30%'}}
              />
              &nbsp;
          </h1> */}
        <nav className="navbar navbar-default navbar-static-top tm_navbar clearfix " role="navigation">
            <ul className="nav sf-menu clearfix">
                <li className={homeClass}><Link to="/home">Home<em></em></Link><i></i><span></span></li>
                <li className={catClass}><Link to="/categories">Categories<em></em></Link><i></i></li>
                <li className={comboClass}><Link to="/combo">Combo<em></em></Link><i></i></li>
                <li className={eventClass}><Link to="/event">Events<em></em></Link><i></i></li>
                <li className={galleryClass}><Link to="/gallery">Gallery<em></em></Link><i></i></li>
            </ul>
        </nav>
        </div>
    </div>
    <div className="container mobile_menu"> 
    <div className="mb-3 d-flex justify-content-center">
          
          <img 
              src={logo} 
              alt="logo"
              style={{
                width: '30%',
                position: 'absolute',
                background: '#f7e8da',
                borderRadius: '50%'
            }}
              />
          
          
          
          
      </div>
      <div className="d-flex align-items-center justify-content-between" style={{position:'relative'}}>
        
          
              {/* <img 
              src={logo} 
              alt="logo"
              style={{width:'10%'}}
              /> */}

            <img 
              src={menu_mobile} 
              alt="drawer"
              style={{width:'8%'}}
              onClick={openDrawer}
            />
              
       
        {drawerStat===true &&
                <div style={{
                    position:'absolute',
                    top:'5%',
                    left:0,
                    right:'5%',
                    backgroundColor:'#fff',
                    zIndex:999
                }}>
                    <p onClick={()=>gotoPage('home')}>Home</p>
                    <p onClick={()=>gotoPage('categories')}>Categories</p>
                    <p onClick={()=>gotoPage('combo')}>Combo</p>
                    <p onClick={()=>gotoPage('event')}>Events</p>
                    <p onClick={()=>gotoPage('gallery')}>Gallery</p>
                </div>
            }
        </div>
    </div>
</header>
  );
}

export default Header;
