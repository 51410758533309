

  export const convertDateTime = (value) =>{
        var tempDate=new Date(value)
        var dd=tempDate.getDate();
        var mm=tempDate.getMonth()+1;
        var crtMM=('0' + mm).slice(-2);
        var crtDD=('0' + dd).slice(-2)
        var yy=tempDate.getFullYear();
        //var crtDate=crtDD+'-'+crtMM+'-'+yy;
        var crtDate=yy+'-'+crtMM+'-'+crtDD;

        // var hh=tempDate.getHours();
        // var mm=tempDate.getMinutes();
        // var ss=tempDate.getSeconds();
        // var crtTime=hh+':'+mm+':'+ss;
        var dt=crtDate
        return dt
  }

  export const convertTime = (value) =>{
      var tempDate=new Date(value)
      var hh=tempDate.getHours();
      var crtHH=('0' + hh).slice(-2);
      var mm=tempDate.getMinutes();
      var crtMM=('0' + mm).slice(-2);
      var crtTime=crtHH+':'+crtMM
      var dt=crtTime
      return dt
}

  