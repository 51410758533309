import React, { useEffect, useState, } from "react";
import companyLogo from '../assets/images/logo-3-bg.png';
import Header from "../common/Header";
import SideMenu from "../common/SideMenu";
import Footer from "../common/Footer";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { AdminProfile } from "../Context/AdminContext";
import { Modal } from "react-bootstrap";
import config from "../Config/Config.json"
import BootstrapSwitchButton from "bootstrap-switch-button-react";

const CategoryPage = () => {

  const navigate = useNavigate();
  const { authData} = useAuth();
  const {categoryList,addCategory,updateCategory,activeInactiveCategory}=AdminProfile();
  const [categoriesModal, setcategoriesModal] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [imageHandler, setImageHandler] = useState(null)
  const [editedCatId, setEditedCatId] = useState(0)

  useEffect(() => {
  }
    , [authData]);

    useEffect(() => {
    }
      , [categoryList]);

    const openCategoeyModal=()=>{
      setEditedCatId(0)
      setCategoryName('')
      setcategoriesModal(true)
    }

    const closecategoriesModal=()=>{
      setcategoriesModal(false)
    }

    const uploadImage=(e)=>{
      setImageHandler(e.target.files[0])
    }

    const saveCategory=async()=>{
      if(categoryName==='' || imageHandler===null)
      {
        alert('All * marks fields are mandatory')
      }
      else{
        let resp=''
        const formData = new FormData();
        formData.append('id', editedCatId)
        formData.append('name', categoryName)
        formData.append('imageFile', imageHandler)
        if(editedCatId===0){
          resp=await addCategory(formData)
        }
        else{
          resp=await updateCategory(formData)
        }
        
        setcategoriesModal(false)
        if(resp.data.status==="success"){
          alert(resp.data.message)
        }
        else{
          alert("Failed to save")
        }
      }
    }

    const openEditSection=(data)=>{
      setEditedCatId(data.id)
      setCategoryName(data.name)
      setcategoriesModal(true)
    }

    const changeStatus=async(id)=>{
      const resp=await activeInactiveCategory(id)
    }

    return(
      <div className="container-scroller">
      
      
      <Header/>
      
      <div className="container-fluid page-body-wrapper">
        
        <SideMenu/>
        
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="page-header d-flex justify-conetent-between">
              <h3 className="page-title">
                 Manage Category
              </h3>

              <button className="btn btn-dark btn-fw" onClick={openCategoeyModal}>
                  Add
              </button>
              
            </div>
            
            
            <div className="row">
            {categoryList.map((data, index) => (
              <div className="col-3 grid-margin" key={index}>
                
                <div className="card">
                  <img src={config.FILE_URL+data.imageName+'?d='+new Date()} style={{width:'100%',height:'134px'}}/>
                  <div className="card-body">
                    
                    <h4 className="card-title">{data.name}</h4>
                    

                      <button className="btn btn-outline-dark btn-sm" onClick={()=>openEditSection(data)}>
                          Edit
                      </button>
                      <BootstrapSwitchButton
                              checked={data.isActive} 
                              onstyle="success" 
                              offstyle="secondary" 
                              size="sm"
                              style="w-50 mx-2"
                              onChange={() => changeStatus(data.id)}
                      />
                    
                  </div>
                </div>
              </div>
            ))}
            </div>
            
          </div>
          <Footer/>
          
          
        </div>
        
      </div>

      {/* ------------Add Edit Category Modal------------ */}

      <Modal
          show={categoriesModal}
          onHide={closecategoriesModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 mb-3">
                <label className="form-label">Category Name <span style={{color:'red'}}>*</span></label>
                <input 
                    type="text" 
                    className="form-control mb-2" 
                    placeholder="Type Category Name"
                    value={categoryName} 
                    onChange={(e) => setCategoryName(e.target.value)} 
                />
                <label className="form-label">Upload Image <span style={{color:'red'}}>*</span></label>
                <input 
                  type="file" 
                  className="form-control mb-2" 
                  onChange={uploadImage}
                  />

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            {/* {categoryId === 0 &&
              <div className="text-danger">All fields are mandatory</div>
            } */}
            <button className="btn btn-outline-dark btn-fw" onClick={saveCategory} style={{ width: '20%' }}>
              Save
            </button>
          </Modal.Footer>
        </Modal>
      
    </div>
    )
}

export default CategoryPage;