import React, { useEffect, useState, } from "react";

import AwesomeSlider from 'react-awesome-slider';
//import withCaption from 'react-awesome-slider/dist/captioned';
import 'react-awesome-slider/dist/styles.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import config from "../Config/Config.json"

import fb from '../img/fb.png'
import insta from '../img/insta.png'


import Header from "../common/Header";
import { useNavigate } from "react-router-dom";
import { UserProfile } from "../Context/UserContext";
import { convertDateTime, convertTime } from "../admin/Config/CommonFunction";
import { Modal } from "react-bootstrap";

//const CaptionedSlider = withCaption(AwesomeSlider);

const ComboScreen = () => {

    const navigate = useNavigate();
    const {allCombo}=UserProfile();
    const [comboModal,setComboModal]=useState(false)
    const [comboName,setComboName]=useState('')
    const [comboDesc,setComboDesc]=useState('')

    useEffect(() => {
        window.scrollTo(0, 0)
    }
      , [allCombo]);

      const viewCombo=(value)=>{
        setComboName(value.name)
        setComboDesc(value.description)
        setComboModal(true)
    }
    const closeComboModal=()=>{
        setComboModal(false)
    }

    return(
      <div>
     <Header/>

  <div className="global"> 
    
 
  
        
    <div className="forcombo py-2 container_min_height">
        <div className="col-lg-12">
                <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                    <h3 className="menu_heading_combo">Our Exclusive Combo</h3>
                    {/* <button className="btn-default btn2" onClick={gotoCombo}>View All</button> */}
                </div>
                <div className="row d-flex align-items-center justify-content-center" style={{flexWrap:'wrap'}}>
                {allCombo.map((data, index) => (
                    <div className="col-lg-3 mb-5" key={index}>
                        <div className="thumbnail">
                            <figure style={{margin:'0 0 0 0'}}>
                                <img src={config.FILE_URL+data.imageName+'?d='+new Date()} alt="" style={{width:'100%',height:'342px'}}/>
                            </figure>
                            <div className="caption">
                                <div className="event_block">
                                        <p className="title">{data.name}</p>
                                        <p className="description mb-2">
                                            {data.description.substring(0, 120)}...
                                        </p>
                                        
                                        
                                    </div>
                                    <button className="btn-default btn2" onClick={()=>viewCombo(data)}>View Offer</button>
                                
                            </div>
                        </div>
                    </div>
                ))}
            
                </div>

                <Modal
          show={comboModal}
          onHide={closeComboModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
                <Modal.Title>{comboName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 mb-3">
                
                
                
                <p>{comboDesc}</p>
                

              </div>
            </div>
          </Modal.Body>
          </Modal>
        </div>
    </div>
   

    

   
    
        
    <div className="footer_dark py-2 px-3">
        <div className=" d-flex align-items-center justify-content-between">
            <h6>The Lighter Side &copy; 2024</h6>
            <div className="d-flex align-items-center justify-content-between">
                <img src={fb} alt="fb" style={{height:30,width:30}}/>
                <img src={insta} alt="insta" style={{height:30,width:30}}/>
            </div>
        </div>
        
    </div>
    
</div>
  </div>
    )
}

export default ComboScreen;