import React, { useEffect, useState, } from "react";
import companyLogo from '../../img/logo.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../Context/AuthContext";

const LoginPage = () => {

  const navigate = useNavigate();
  const { logIn} = useAuth();
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')


const usernameHandler = (e) => {
    setUserName(e.target.value)
}

const passwordHandler = (e) => {
    setPassword(e.target.value)
}

  const doLogin=async()=>{
    var sendLoginData = {
      loginid: username,
      password: password
  }
    const resp = await logIn(sendLoginData)
    if(resp===undefined){
      alert("Invalid login")
    }
    else{
      navigate("/admin/banner")
    }
  }

    return(
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-5">
                <div className="brand-logo">
                  <img src={companyLogo} style={{height:70,width:70}}/>
                </div>
                <h4>Hello! let's get started</h4>
                <h6 className="font-weight-light">Sign in to continue.</h6>
                <div className="pt-3">
                  <div className="form-group">
                    <input type="email" 
                    className="form-control form-control-lg"  
                    placeholder="Username"
                    value={username}
                    onChange={usernameHandler}
                    />
                  </div>
                  <div className="form-group">
                    <input 
                    type="password" 
                    className="form-control form-control-lg"  
                    placeholder="Password"
                    value={password}
                    onChange={passwordHandler}
                    />
                  </div>
                  <div className="mt-3 d-grid gap-2">
                    <button 
                    className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" 
                    onClick={doLogin}
                    
                    >SIGN IN</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      
    </div>
    )
}

export default LoginPage;