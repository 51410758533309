import React, { useEffect, useState,} from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

const SideMenu=(props)=> {
  const navigate = useNavigate();
  
  return (
    <nav className="sidebar sidebar-offcanvas my-5" id="sidebar">
          <ul className="nav">
            {/* <li className="nav-item nav-profile">
              <a href="#" className="nav-link">
                
                <div className="nav-profile-text d-flex flex-column">
                  <span className="font-weight-bold mb-2">Admin</span>
                  
                </div>
                
              </a>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/admin/banner">
                <span className="menu-title">Manage Banner</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/category">
                <span className="menu-title">Manage Category</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/menu">
                <span className="menu-title">Manage Menu</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/combo">
                <span className="menu-title">Manage Combo</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/event">
                <span className="menu-title">Manage Event</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/gallery">
                <span className="menu-title">Manage Gallery</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/contact">
                <span className="menu-title">Manage Contact</span>
              </Link>
            </li>
           
          </ul>
        </nav>
  );
}

export default SideMenu;
