import React, { useEffect, useState, } from "react";
import companyLogo from '../assets/images/logo-3-bg.png';
import Header from "../common/Header";
import SideMenu from "../common/SideMenu";
import Footer from "../common/Footer";
import { useAuth } from "../Context/AuthContext";
import { AdminProfile } from "../Context/AdminContext";
import { Modal } from "react-bootstrap";
import config from "../Config/Config.json"
const GalleryPage = () => {
  const { authData} = useAuth();
  const {galleryList,addGallery,deleteGallery}=AdminProfile();
  const [categoriesModal, setcategoriesModal] = useState(false);
  const [imageHandler, setImageHandler] = useState(null)

  useEffect(() => {
  }
    , [authData]);

    useEffect(() => {
      
    }
      , [galleryList]);

      const openCategoeyModal=()=>{
        setcategoriesModal(true)
      }
  
      const closecategoriesModal=()=>{
        setcategoriesModal(false)
      }
  
      const uploadImage=(e)=>{
        setImageHandler(e.target.files[0])
      }

      const saveGallery=async()=>{
        if(imageHandler===null)
          {
            alert('All * marks fields are mandatory')
          }
          else{
            const formData = new FormData();
            formData.append('id', 0)
            formData.append('imageFile', imageHandler)
            
            const resp=await addGallery(formData)
            setcategoriesModal(false)
            if(resp.data.status==="success"){
              alert(resp.data.message)
            }
            else{
              alert("Failed to save")
            }
      }
    }
    const deleteGal=async(id)=>{
      const resp=await deleteGallery(id)

    }
    return(
      <div className="container-scroller">
      
      
      <Header/>
      
      <div className="container-fluid page-body-wrapper">
        
        <SideMenu/>
        
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="page-header d-flex justify-conetent-between">
              <h3 className="page-title">
                 Manage Gallery
              </h3>

              <button className="btn btn-dark btn-fw" onClick={openCategoeyModal}>
                  Add
              </button>
              
            </div>
            
            
            <div className="row">
            {galleryList.map((data, index) => (
              <div className="col-3 grid-margin" key={index}>
                <div className="card">
                  <img src={config.FILE_URL+data.imageName} style={{width:'100%',height:'134px'}}/>
                  <div className="card-body">
                      
                      <button 
                      className="btn btn-outline-danger btn-sm"
                      onClick={()=>deleteGal(data.id)}
                      >
                          Delete
                      </button>
                    
                  </div>
                </div>
              </div>
            ))}
            </div>
            
          </div>
          <Footer/>
          
          
        </div>
        
      </div>

      {/* ------------Add Edit Category Modal------------ */}

      <Modal
          show={categoriesModal}
          onHide={closecategoriesModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Gallery</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 mb-3">
                <label className="form-label">Upload Image <span style={{color:'red'}}>*</span></label>
                <input 
                  type="file" 
                  className="form-control mb-2" 
                  onChange={uploadImage}
                  />

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            {/* {categoryId === 0 &&
              <div className="text-danger">All fields are mandatory</div>
            } */}
            <button className="btn btn-outline-dark btn-fw" onClick={saveGallery} style={{ width: '20%' }}>
              Save
            </button>
          </Modal.Footer>
        </Modal>
      
    </div>
    )
}

export default GalleryPage;