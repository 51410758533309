// import './Css.css';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';

import React, {
useState,
useEffect
} from "react";
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import BannerPage from './pages/BannerPage';
import CategoryPage from './pages/CategoryPage';
import MenuPage from './pages/MenuPage';
import ComboPage from './pages/ComboPage';
import EventPage from './pages/EventPage';
import GalleryPage from './pages/GalleryPage';
import ContactPage from './pages/ContactPage';

const NavigationPage=()=> {

  return (

        <Routes>
            <Route exact path="/" Component={LoginPage}/>
            <Route exact path="/dashboard" Component={DashboardPage}/>
            <Route exact path="/banner" Component={BannerPage}/>
            <Route exact path="/category" Component={CategoryPage}/>
            <Route exact path="/menu" Component={MenuPage}/>
            <Route exact path="/combo" Component={ComboPage}/>
            <Route exact path="/event" Component={EventPage}/>
            <Route exact path="/gallery" Component={GalleryPage}/>
            <Route exact path="/contact" Component={ContactPage}/>
        </Routes>
    
  );
}


export default NavigationPage;
