import React, { useEffect, useState,} from "react";
import { useNavigate } from 'react-router-dom';

const Footer=(props)=> {
  const navigate = useNavigate();
  
  return (
    <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2024 The Lighter Side. All rights reserved.</span>
            </div>
    </footer>
  );
}

export default Footer;
