import React, { useEffect, useState, } from "react";

import AwesomeSlider from 'react-awesome-slider';
//import withCaption from 'react-awesome-slider/dist/captioned';
import 'react-awesome-slider/dist/styles.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import config from "../Config/Config.json"
import event1 from '../img/2150951243.jpg'
import fb from '../img/fb.png'
import insta from '../img/insta.png'


import Header from "../common/Header";
import { useNavigate } from "react-router-dom";
import { UserProfile } from "../Context/UserContext";
import { convertDateTime, convertTime } from "../admin/Config/CommonFunction";

//const CaptionedSlider = withCaption(AwesomeSlider);

const EventScreen = () => {

    const navigate = useNavigate();
    const {allEvent}=UserProfile();

    useEffect(() => {
        window.scrollTo(0, 0)
    }
      , [allEvent]);

      const gotoDetails=(id)=>{
        navigate('/eventdetails',{ state: { event_id: id } })
      }

    return(
      <div>
     <Header/>

  <div className="global"> 
    
 
  <div className="container container_min_height">
        
        <div className="col-lg-12 events mb-5">
            <div className="d-flex align-items-center justify-content-between pt-3 mb-4">
                <h3 className="menu_heading">Our Events</h3>
                
            </div>
            <div className="row d-flex align-items-center" style={{flexWrap:'wrap'}}>
            {allEvent.map((data, index) => (
                <div className="col-lg-4" key={index}>
                    <div className="thumbnail">
                        <figure style={{margin:'0 0 0 0'}}>
                            <img src={data.defaultImage===null?event1:config.FILE_URL+data.defaultImage} alt="" style={{width:'100%',height:'274px'}}/>
                        </figure>
                        <div className="caption">
                            
                            <div className="event_block">
                                <p className="title">{data.name}</p>
                                <p className="description mb-2">
                                    {data.description.substring(0, 120)}...
                                </p>
                                <p className="title">{convertDateTime(data.eventDate)} {convertTime(data.eventDate)}</p>
                            </div>

                            <button className="btn-default btn1" onClick={()=>gotoDetails(data.id)}>Read More</button>
                            
                        </div>
                    </div>
                </div>
            ))}
            </div>
        </div>
    </div>

    

   
    
        
    <div className="footer_dark py-2 px-3">
        <div className=" d-flex align-items-center justify-content-between">
            <h6>The Lighter Side &copy; 2024</h6>
            <div className="d-flex align-items-center justify-content-between">
                <img src={fb} alt="fb" style={{height:30,width:30}}/>
                <img src={insta} alt="insta" style={{height:30,width:30}}/>
            </div>
        </div>
        
    </div>
    
</div>
  </div>
    )
}

export default EventScreen;