import React, { useEffect, useState, } from "react";
import companyLogo from '../assets/images/logo-3-bg.png';
import Header from "../common/Header";
import SideMenu from "../common/SideMenu";
import Footer from "../common/Footer";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { AdminProfile } from "../Context/AdminContext";
import { Modal, Tooltip } from "react-bootstrap";
import edit from "../assets/images/edit.png";
import trash from "../assets/images/trash.png"
import {convertDateTime} from "../Config/CommonFunction.js"
import BootstrapSwitchButton from "bootstrap-switch-button-react";
const ComboPage = () => {

  const navigate = useNavigate();
  const { authData} = useAuth();
  const {comboList,addCombo,updateCombo,activeInactiveCombo}=AdminProfile();
  const [categoriesModal, setcategoriesModal] = useState(false);
  const [comboName, setComboName] = useState('');
  const [comboValidFrom, setComboValidFrom] = useState('');
  const [comboValidTo, setComboValidTo] = useState('');
  const [comboDesc, setComboDesc] = useState('');
  const [imageHandler, setImageHandler] = useState(null)
  const [editedComboId, setEditedComboId] = useState(0);
  const [modalTitle, setModalTitle] = useState('Add Combo');

  useEffect(() => {
  }
    , [authData]);

    useEffect(() => {
      
    }
      , [comboList]);

    const openCategoeyModal=()=>{
      setEditedComboId(0)
      setComboDesc('')
      setComboName('')
      setComboValidTo('')
      setComboValidFrom('')
      setModalTitle('Add Combo')
      setcategoriesModal(true)
    }

    const closecategoriesModal=()=>{
      setcategoriesModal(false)
    }

    const uploadImage=(e)=>{
      setImageHandler(e.target.files[0])
    }

    const saveCategory=async()=>{
      if(comboName==='' || comboValidFrom==='' || comboValidTo==='' || comboDesc==='')
      {
        alert('All * marks fields are mandatory')
      }
      else{
        let resp='';
        const formData = new FormData();
        formData.append('id', editedComboId)
        formData.append('name', comboName)
        formData.append('ValidFrom', comboValidFrom)
        formData.append('ValidTo', comboValidTo)
        formData.append('Description', comboDesc)
        formData.append('imageFile', imageHandler)
        if(editedComboId===0){
          resp=await addCombo(formData)
        }
        else{
          resp=await updateCombo(formData)
        }
        
        setcategoriesModal(false)
        if(resp.data.status==="success"){
          alert(resp.data.message)
        }
        else{
          alert("Failed to save")
        }
      }

      

    }
    const openEditSection=(data)=>{
      setEditedComboId(data.id)
      setComboDesc(data.description)
      setComboName(data.name)
      setComboValidTo(convertDateTime(data.validTo))
      setComboValidFrom(convertDateTime(data.validFrom))
      setModalTitle('Edit Combo')
      setcategoriesModal(true)
      
    }
    const changeStatus=async(id)=>{
      const resp=await activeInactiveCombo(id)
    }
    return(
      <div className="container-scroller">
      
      
      <Header/>
      
      <div className="container-fluid page-body-wrapper">
        
        <SideMenu/>
        
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="page-header d-flex justify-conetent-between">
              <h3 className="page-title">
                 Manage Combo
              </h3>

              <button className="btn btn-dark btn-fw" onClick={openCategoeyModal}>
                  Add
              </button>
              
            </div>
            
            
            <div className="row">
            
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th> Name </th>
                            <th> Description </th>
                            <th> Valid from </th>
                            <th> Valid to </th>
                            <th> Status </th>
                            <th> </th>
                            <th> </th>
                          </tr>
                        </thead>
                        <tbody>
                        {comboList.map((data, index) => (
                          <tr key={index}>
                            <td>
                              {data.name}
                            </td>
                            <td> 
                              {data.description.substring(0, 20)}...
                                
                            </td>
                            <td>
                              {convertDateTime(data.validFrom)}
                            </td>
                            <td>
                              {convertDateTime(data.validTo)}
                            </td>
                            <td> 
                            {data.isActive===true?(
                                <label className="badge badge-gradient-success">Active</label>
                              ):(
                                <label className="badge badge-gradient-danger">Inactive</label>
                              )}
                            </td>
                            <td> 
                            <button 
                            className="btn btn-inverse-primary btn-icon"
                            onClick={()=>openEditSection(data)}
                            >
                                  <img 
                                    src={edit}
                                    style={{
                                      height:'24px',
                                      width:'24px'
                                    }}
                                  />
                              </button>

                              
                            </td>
                            <td>
                              <BootstrapSwitchButton 
                              checked={data.isActive} 
                              onstyle="success" 
                              offstyle="secondary" 
                              size="lg"
                              onChange={() => changeStatus(data.id)}
                              />
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                
              </div>
            
            </div>
            
          </div>
          <Footer/>
          
          
        </div>
        
      </div>

      {/* ------------Add Edit Category Modal------------ */}

      <Modal
          show={categoriesModal}
          onHide={closecategoriesModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 mb-3">
                <label className="form-label">Combo Name <span style={{color:'red'}}>*</span></label>
                <input 
                    type="text" 
                    className="form-control mb-2" 
                    placeholder="Type Category Name"
                    value={comboName} 
                    onChange={(e) => setComboName(e.target.value)} 
                />
                <label className="form-label">Valid from <span style={{color:'red'}}>*</span></label>
                <input 
                    type="date" 
                    //readOnly={true}
                    className="form-control mb-2" 
                    placeholder="Type Category Name"
                    value={comboValidFrom} 
                    onChange={(e) => setComboValidFrom(e.target.value)} 
                />
                <label className="form-label">Valid to (Left blank for lifetime)</label>
                <input 
                    type="date" 
                    //readOnly={true}
                    className="form-control mb-2" 
                    placeholder="Type Category Name"
                    value={comboValidTo} 
                    onChange={(e) => setComboValidTo(e.target.value)} 
                />
                <label className="form-label">Description <span style={{color:'red'}}>*</span></label>
                <textarea 
                className="form-control mb-2"
                onChange={(e) => setComboDesc(e.target.value)} 
                value={comboDesc}
                />
                <label className="form-label">Upload Image <span style={{color:'red'}}>*</span></label>
                <input 
                  type="file" 
                  className="form-control mb-2" 
                  onChange={uploadImage}
                  />

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            {/* {categoryId === 0 &&
              <div className="text-danger">All fields are mandatory</div>
            } */}
            <button className="btn btn-outline-dark btn-fw" onClick={saveCategory} style={{ width: '20%' }}>
              Save
            </button>
          </Modal.Footer>
        </Modal>
      
    </div>
    )
}

export default ComboPage;