import React, { useEffect, useState, } from "react";
import './assets/css/admin.css';
import NavigationPage from "./NavigationPage";
import { AuthProvider } from "./Context/AuthContext";
import { AdminProvider } from "./Context/AdminContext";


const DefaultPage = () => {

    return(
        <AuthProvider>
            <AdminProvider>
                <NavigationPage/>
            </AdminProvider>
        </AuthProvider>
        
    )
}

export default DefaultPage;